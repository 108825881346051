import React, { useState, useEffect, useMemo, Fragment } from "react";
import IdentityModal, {
  useIdentityContext,
} from "react-netlify-identity-widget";
import "react-netlify-identity-widget/styles.css";
import { deleteReservation, getSnapshots } from "../services";
import { css } from "@emotion/core";
import Spinner from "../components/Spinner";
import ErrorMsg from "../components/ErrorMsg";
import { Table, Tabs } from "../components/Admin";
import ReservationTypeSelector, {
  type as reservationType,
} from "../components/Admin/ReservationTypeSelector";
import { colors } from "../styles/constants";
import ReactPaginate from "react-paginate";
import { PromoCodesView } from "../components/Admin/views";
import DeleteButton from "../components/Admin/DeleteButton";

function BookingHandlerPage() {
  const isDevelop = process.env.NODE_ENV === "develop";
  const identity = useIdentityContext();
  const isLoggedIn = (identity && identity.isLoggedIn) || isDevelop;
  const [isModal, setIsModal] = useState(!isDevelop && !isLoggedIn);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [isDeletingError, setIsDeletingError] = useState(false);
  const [fetchingState, setFetchingState] = useState({
    isLoading: false,
    isError: false,
  });
  const [selectedReservationType, setSelectedReservationType] = useState(
    reservationType.ACTIVE
  );
  const [snapshots, setSnapshots] = useState([]);

  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    if (isLoggedIn) {
      fetchReservations();
    }
  }, [isLoggedIn, selectedReservationType]);

  const fetchReservations = async () => {
    try {
      setFetchingState({
        isLoading: true,
        isError: false,
      });
      const response = await getSnapshots(selectedReservationType);
      setSnapshots(response.data);
      setActivePage(0);
      setFetchingState({
        isLoading: false,
        isError: false,
      });
    } catch {
      setFetchingState({
        isLoading: false,
        isError: true,
      });
    }
  };

  const handleCloseModal = () => {
    if (isLoggedIn) {
      setIsModal(false);
    }
  };

  const handleLogOut = () => {
    setIsModal(true);
  };

  const handleReservationDelete = async () => {
    try {
      await deleteReservation(reservationToDelete);
      // fetchReservations()
      setSnapshots((snaps) => {
        return snaps.filter((s) => s.ref["@ref"].id !== reservationToDelete);
      });
      setReservationToDelete(null);
      setIsDeletingError(false);
    } catch {
      setIsDeletingError(true);
    }
  };

  const handleDeleteClick = (id) => () => {
    setReservationToDelete(id);
  };

  const handleReservationTypeSelect = (type) => () => {
    setSelectedReservationType(type);
  };

  const handlePageChange = (arg) => {
    setActivePage(arg.selected);
  };

  const tableHeader = [
    { label: "Nev", name: "name" },
    { label: "Email", name: "email" },
    { label: "Telefon", name: "phone" },
    { label: "Vendegek", name: "numberOfGuests" },
    { label: "Datum", name: "bookedRange" },
    { label: "Fizetes", name: "paymentMethod" },
    { label: "", name: "deleteBtn" },
  ];

  const tableRows = useMemo(
    () =>
      snapshots
        .map((s) => ({
          key: s.ref["@ref"].id,
          name: s.data.familyname,
          email: s.data.email,
          numberOfGuests: s.data.numberOfGuests,
          bookedRange: s.data.bookedRange,
          paymentMethod: s.data.paymentMethod,
          phone: s.data.phone,
          deleteBtn: (
            <DeleteButton onClick={handleDeleteClick(s.ref["@ref"].id)} />
          ),
        }))
        .sort((a, b) => {
          const aDate = new Date(a.bookedRange.split(" - ")[1]);
          const bDate = new Date(b.bookedRange.split(" - ")[1]);
          return aDate - bDate;
        }),
    [snapshots]
  );

  return (
    <section className="section">
      {isLoggedIn && (
        <div className="container">
          {isDeletingError && (
            <ErrorMsg
              header="HIBA!"
              body="A törlés során hiba lépett fel! Frissítsd a lapot és próbáld meg mégegyszer! Amennyiben a hiba továbbra is fennáll, hívd Gergőt!"
            />
          )}
          <div
            className="box"
            css={css`
              margin-top: 8rem;
            `}
          >
            <button
              className="button is-primary"
              onClick={handleLogOut}
              css={css`
                float: right;
              `}
            >
              Kijelentkezes
            </button>
            <Tabs
              contents={[
                {
                  title: "Foglalasok",
                  body: (
                    <Fragment>
                      <ReservationTypeSelector
                        selected={selectedReservationType}
                        onChange={handleReservationTypeSelect}
                      />
                      {((fetchingState.isLoading || fetchingState.isError) && (
                        <div
                          css={css`
                            display: flex;
                            justify-content: center;
                          `}
                          className="box"
                        >
                          {(fetchingState.isLoading && <Spinner />) || (
                            <ErrorMsg
                              header="HIBA!"
                              body="A foglalási naptár betöltése során hiba lépett fel! Frissítsd a lapot! Amennyiben a hiba továbbra is fennáll, hívd Gergőt!"
                            />
                          )}
                        </div>
                      )) || (
                        <div css={styles.tableWithPaginate}>
                          <div css={styles.tableContainer}>
                            <Table
                              css={styles.table}
                              header={tableHeader}
                              rows={tableRows.slice(
                                activePage * 8,
                                8 + activePage * 8
                              )}
                            />
                          </div>
                          <ReactPaginate
                            pageCount={Math.ceil(tableRows.length / 8)}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={2}
                            previousLabel={"<"}
                            nextLabel={">"}
                            containerClassName="page_container"
                            pageClassName="page_number"
                            previousClassName="stepping_label"
                            nextClassName="stepping_label"
                            activeClassName="active_page"
                            onPageChange={handlePageChange}
                          />
                        </div>
                      )}
                    </Fragment>
                  ),
                },
                {
                  title: "Kupon kodok",
                  body: <PromoCodesView />,
                },
              ]}
            />
          </div>
        </div>
      )}
      <div
        className={`modal is-clipped ${
          reservationToDelete !== null ? "is-active" : ""
        }`}
      >
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box is-text-centered">
            <p>Biztos torolni akarod a foglalast?</p>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <button
                className="button is-danger"
                onClick={() => setReservationToDelete(null)}
              >
                Nem
              </button>
              <button
                className="button is-success "
                onClick={handleReservationDelete}
              >
                Igen
              </button>
            </div>
          </div>
        </div>
      </div>
      <IdentityModal showDialog={isModal} onCloseDialog={handleCloseModal} />
    </section>
  );
}

export default BookingHandlerPage;

const styles = {
  tableContainer: css`
    min-height: 500px;
  `,
  tableWithPaginate: css`
    & .page_container {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    & .page_number,
    & .stepping_label {
      padding-right: 2rem;
      & a {
        color: ${colors.primary800};
      }
      & a:focus {
        outline: none;
      }
    }
    & .active_page {
      font-weight: bold;
    }
  `,
  table: css`
    width: 100%;
  `,
};
