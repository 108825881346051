import React from "react"
import { useState } from "react"
import downArrowIcon from "../../images/down_arrow.svg"
import { css } from "@emotion/core"
import { colors } from "../../styles/constants"

function Dropdown({
    options,
    selectedOption,
    label,
    onSelect,
    required = false,
    className,
}) {
    const [isOpen, setIsOpen] = useState(false)

    const handleOnClick = selected => () => {
        onSelect(selected)
    }

    return (
        <div className={`field ${className}`}>
            <label className="label">
                {label}
                {required && <span css={styles.requiredSign}>*</span>}
            </label>
            <div
                className={`dropdown ${isOpen ? "is-active" : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        css={styles.ddButton}
                    >
                        <span>{options[selectedOption]}</span>
                        <span className="icon is-small">
                            <img
                                src={downArrowIcon}
                                alt="down arrow"
                                css={styles.ddICon}
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content" css={styles.ddBody}>
                        {Object.keys(options).map(option => (
                            <a
                                className="dropdown-item"
                                key={option}
                                onClick={handleOnClick(option)}
                            >
                                {options[option]}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dropdown

const styles = {
    ddButton: css`
        border: 0px;
        background: ${colors.coldGray};
        height: 3rem;
        display: flex;
        justify-content: space-between;
    `,
    ddBody: css`
        background: ${colors.coldGray};
        max-height: 11rem;
        overflow: scroll;
    `,
    ddIcon: css`
        margin-bottom: 0;
    `,
    requiredSign: css`
        color: ${colors.red400};
    `,
}
