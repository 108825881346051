import React, { useEffect, useState } from "react";
import {
  getPromoCodes,
  savePromoCode,
  deletePromoCode,
} from "../../../services/promoCodes";
import { useMemo } from "react";
import Table from "../Table";
import { css } from "@emotion/core";
import StatusWrapper from "../../StatusWrapper";
import DeleteButton from "../DeleteButton";
import { Modal } from "../../Modal";
import { useStatusReducer, statusTypes } from "../../../utils/hooks";
import InputField from "../../Booking/InputField";
import { Dropdown } from "../../Dropdown";
import { format } from "date-fns";
import ErrorMsg from "../../ErrorMsg";

function PromoCodesView() {
  const [codeToDelete, setCodeToDelete] = useState(null);

  const [state, dispatch] = useStatusReducer([]);

  const [newCode, setNewCode] = useState({
    code: "",
    type: "percentage",
    value: "",
  });

  const [savingCode, setSavingCode] = useState({
    isLoading: false,
  });

  const [sideError, setSideError] = useState(false);

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  const fetchPromoCodes = async () => {
    try {
      dispatch({
        type: statusTypes.FETCH,
      });
      const response = await getPromoCodes();
      dispatch({
        type: statusTypes.RESOLVE,
        payload: response.data,
      });
    } catch {
      dispatch({
        type: statusTypes.REJECT,
      });
      setSideError(true);
    }
  };

  const handleDeleteClick = (id) => () => {
    setCodeToDelete(id);
  };

  const handleDeletePromoCode = async () => {
    try {
      const response = await deletePromoCode(codeToDelete);
      dispatch({
        type: statusTypes.RESOLVE,
        payload: state.data.filter((d) => {
          const [ref] = d;
          return ref["@ref"].id !== response.ref["@ref"].id;
        }),
      });
    } catch (error) {
      console.error(error);
      setSideError(true);
    } finally {
      setCodeToDelete(null);
    }
  };

  const handleTypeSelect = (selected) => {
    setNewCode({
      ...newCode,
      type: selected,
    });
  };

  const handlePromoCodeChange = (event) => {
    setNewCode({
      ...newCode,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const handleNewPromoClick = async () => {
    try {
      setSavingCode({ ...savingCode, isLoading: true });
      const codeToSave = {
        ...newCode,
        value:
          newCode.type === "percentage"
            ? Number(newCode.value) / 100
            : Number(newCode.value),
        created: format(new Date(), "y-MM-dd"),
        used: 0,
      };
      const response = await savePromoCode(codeToSave);
      dispatch({
        type: statusTypes.RESOLVE,
        payload: [...state.data, response],
      });
      setSavingCode({ ...savingCode, isLoading: false });
    } catch (error) {
      console.error(error);
      setSideError(true);
      setSavingCode({ ...savingCode, isLoading: false });
    }
  };

  const tableHeader = [
    { label: "Kod", name: "code" },
    { label: "Kedvezmeny", name: "discount" },
    { label: "Hasznalva", name: "used" },
    { label: "Keszitve", name: "created" },
    { label: "", name: "deleteBtn" },
  ];

  const rows = useMemo(
    () =>
      state.data.map((c) => {
        const [ref, code, type, value, used, created] = c;
        return {
          key: ref["@ref"].id,
          code,
          discount: (
            <div>
              {type === "percentage" ? `${value * 100}%` : `-${value} Ft`}
            </div>
          ),
          used,
          created,
          deleteBtn: (
            <DeleteButton onClick={handleDeleteClick(ref["@ref"].id)} />
          ),
        };
      }),
    [state.data]
  );

  const typeOptions = {
    percentage: "Szazalek",
    fixedAmount: "Fix osszeg",
  };

  return (
    <div>
      <div css={styles.newCode}>
        <InputField
          label="Kod"
          name="code"
          css={styles.input}
          value={newCode.code}
          onChange={handlePromoCodeChange}
        />
        <Dropdown
          label="Tipus"
          options={typeOptions}
          selectedOption={newCode.type}
          onSelect={handleTypeSelect}
          css={styles.typeInput}
        />
        <InputField
          label="Ertek"
          name="value"
          type="number"
          css={styles.input}
          value={newCode.value}
          onChange={handlePromoCodeChange}
        />
        <button
          disabled={savingCode.isLoading}
          className={"button"}
          css={styles.newButton}
          onClick={handleNewPromoClick}
        >
          {savingCode.isLoading ? "+..." : "+"}
        </button>
      </div>
      <StatusWrapper
        componentStatus={state.status}
        errorBody={
          "A promo kodok betöltése során hiba lépett fel! Frissítsd a lapot! Amennyiben a hiba továbbra is fennáll, hívd Gergőt!"
        }
      >
        <Table css={styles.table} header={tableHeader} rows={rows} />
      </StatusWrapper>
      <Modal
        title={"Figyelem"}
        isOpen={codeToDelete !== null}
        onClose={() => setCodeToDelete(null)}
        content={
          <div className="box is-text-centered">
            <p>Biztos torolni akarod a promo kodot?</p>
            <div css={styles.modalButtons}>
              <button
                className="button is-danger"
                onClick={() => setCodeToDelete(null)}
              >
                Nem
              </button>
              <button
                className="button is-success "
                onClick={handleDeletePromoCode}
              >
                Igen
              </button>
            </div>
          </div>
        }
      />
      {sideError && (
        <ErrorMsg
          header="HIBA!"
          body={
            "Baj tortent! Frissitsd az oldalt, probald meg ujra, ha tovabbra is baj tortenik, akkor hivd Gergot!"
          }
        />
      )}
    </div>
  );
}

export default PromoCodesView;

const styles = {
  table: css`
    width: 100%;
  `,
  newCode: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40rem;
  `,
  input: css`
    width: 10rem;
  `,
  typeInput: css`
    & button {
      width: 10rem;
    }
  `,
  newButton: css`
    margin-top: 1rem;
  `,
  modalButtons: css`
    display: flex;
    justify-content: space-between;
  `,
};
