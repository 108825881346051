import React, { useState } from "react"
import { css } from "@emotion/core"

function Tabs({ contents }) {
    const [activeTab, setActiveTab] = useState(0)

    return (
        <div>
            <div className="tabs is-boxed">
                <ul>
                    {contents.map((tab, index) => (
                        <li
                            className={activeTab === index && "is-active"}
                            key={tab.title}
                            onClick={() => setActiveTab(index)}
                        >
                            <a>
                                <span>{tab.title}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <h3
                className="title"
                css={css`
                    font-family: "Montserrat", sans-serif;
                `}
            >
                {contents[activeTab].title}
            </h3>
            {contents[activeTab].body}
        </div>
    )
}

export default Tabs
