import React from "react"
import Icons, { icon } from "../Icons"
import { css } from "@emotion/core"
import { sizes, desktopMediaQuery } from "../../styles/constants"

export const type = {
    ACTIVE: "after",
    OUTDATED: "before",
}

function ReservationTypeSelector({ selected, onChange }) {
    return (
        <div css={styles.optionsContainer}>
            <div css={styles.radioContainer}>
                <Icons
                    type={
                        selected === type.ACTIVE
                            ? icon.RADIO_CHECKED
                            : icon.RADIO_UNCHECKED
                    }
                    css={styles.radioButton}
                    onClick={onChange(type.ACTIVE)}
                />
                <span>Aktiv Foglalasok</span>
            </div>
            <div css={styles.radioContainer}>
                <Icons
                    type={
                        selected === type.OUTDATED
                            ? icon.RADIO_CHECKED
                            : icon.RADIO_UNCHECKED
                    }
                    css={styles.radioButton}
                    onClick={onChange(type.OUTDATED)}
                />
                <span>Lejart Foglalasok</span>
            </div>
        </div>
    )
}

export default ReservationTypeSelector

const styles = {
    optionsContainer: css`
        display: flex;
    `,
    radioContainer: css`
        display: flex;
        align-items: center;
        padding: 0 ${sizes.smallScale.xl} ${sizes.smallScale.m} 0;
        ${desktopMediaQuery} {
            padding: 0 ${sizes.smallScale.xl} ${sizes.smallScale.xl} 0;
        }
    `,
    radioButton: css`
        padding-right: ${sizes.smallScale.xs};
        &:hover {
            cursor: pointer;
        }
    `,
}
