import React from "react"
import { css } from "@emotion/core"
import { colors } from "../../styles/constants"

function DeleteButton({ onClick }) {
    return (
        <button
            className="button"
            onClick={onClick}
            css={css`
                background-color: ${colors.red300};
                color: ${colors.red};
                border: none;
            `}
        >
            Torles
        </button>
    )
}

export default DeleteButton
