import React from "react"

function Table({ header, rows, className }) {
    return (
        <table className={`table ${className}`}>
            <thead>
                <tr>
                    {header.map(h => (
                        <th key={h.label}>{h.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((r, i) => (
                    <tr key={r.key}>
                        {header.map((h, i) => (
                            <td key={i}>{r[h.name]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Table
